import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import Pricing from "../../components/section/Pricing/pricing"
import CallToAction from "../../components/section/CallToAction/callToAction"

const PaginasWebPage = () => {
    const data = useStaticQuery(graphql`
    query {
      paginasWebImage1: file(relativePath: { eq: "images/sections/marketing-digital/paginas-web/primera-impresion-si-importa-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      paginasWebImage2: file(relativePath: { eq: "images/sections/marketing-digital/paginas-web/diseno-web-exito-negocio-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      paginasWebImage3: file(relativePath: { eq: "images/sections/marketing-digital/paginas-web/diseno-web-empresas-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Diseño Web"
                description="Agencia de diseño web en Barcelona experta en diseño de páginas webs dinámicas, sencillas, usables, accesibles y optimizadas para todo tipo de dispositivos."
            />

            <BannerPage
                title="Agencia diseño web"
                text="Especialistas en la creación de páginas web corporativas para empresas"
                color="green"
            />

            <SectionTextImage
                title1="La primera impresión "
                title2="¡Sí que importa!"
                text={<Fragment>
                    Porque el envoltorio del paquete es a veces mucho más importante que el propio producto que se ofrece. No se trata de tener una página solo bonita, sino de tener una <strong>página atractiva y funcional a la vez</strong>.  Solo así, es posible desarrollar de forma óptima una marca o negocio en la red.
                    </Fragment>}
                image={data.paginasWebImage1.childImageSharp.fluid}
                imageAlt="La primera impresión si importa - Agencia Diseño Web"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="El diseño de tu web, "
                title2="la clave de tu éxito de tu negocio"
                text={<Fragment>
                    Las páginas webs que llevamos a cabo se caracterizan por ser: <br /><br />
                    <ul class="text-left">
                        <li><strong>Webs personalizadas</strong> y a medida</li>
                        <li>Usables, rápidas, claras y atractivas</li>
                        <li>Seguras y fiables con <strong>certificado SSL</strong></li>
                        <li>Funcionales y <strong>autogestionables</strong> mediante sistemas de gestión de contenidos (CMS) como Wordpress</li>
                        <li>Multi-idioma</li>
                        <li><strong>Responsive design</strong>: adaptada para mobile, tablet &amp; desktop</li>
                        <li><strong>Optimizadas</strong> para el SEO y <strong>orientadas a la conversión</strong></li>
                    </ul>
                </Fragment>}
                image={data.paginasWebImage2.childImageSharp.fluid}
                imageAlt="Diseño web la clave del éxito - Agencia Diseño Web"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Diseño web "
                title2="para empresas"
                text={<Fragment>
                    En IBX somos <strong>expertos en la creación y restyling de páginas web para empresas</strong>, webs corporativas ideadas para convertir, para generar leads. Espacios destinados a definir los productos y servicios de un negocio de forma clara y concisa. <br /><br />
                    Creamos aquel canal que las empresas necesitan para establecer un contacto directo con nuevos clientes.
                </Fragment>}
                image={data.paginasWebImage3.childImageSharp.fluid}
                imageAlt="Diseño web para empresas - Agencia Diseño Web"
                color="green"
                containerTextRight={false}
            />

            <hr className="space-2-bottom space-3-bottom--lg" />

            <Pricing
                color="green"
                silverText="Ideal para empresas que necesiten una carta de presentación"
                silverPrice="850"
                silverPriceCurrency="€"
                silverFeatures={["Instalación Wordpress", "Plantilla y editor personalizado", "Responsive Design", "Hasta 10 páginas", "Optimización de imágenes", "1 idioma", "Instalación de modulos y themes básicos", "Optimización SEO básica"]}
                silverException={["* Los contenidos y las imágenes deberán ser entregadas por el cliente.", "* Hosting, dominio y módulos no incluidos. (En caso de ser necesario, se contabilizará a parte.", "* Formación de Wordpress no incluida. Coste 150€"]}
                goldText="Ideal para empresas con una amplia cartera de servicios"
                goldPrice="1200"
                goldPriceCurrency="€"
                goldFeatures={["Instalación Wordpress", "Plantilla y editor personalizado", "Responsive Design", "Hasta 15 páginas", "Optimización de imágenes", "2 idiomas", "Instalación de modulos avanzados", "Optimización SEO avanzada"]}
                goldException={["* Los contenidos y las imágenes deberán ser entregadas por el cliente.", "* Hosting, dominio y módulos no incluidos. (En caso de ser necesario, se contabilizará a parte.", "* Formación de Wordpress no incluida. Coste 150€"]}
                diamondText="Ideal para amplia cartera de servicios y cobertura de mercado"
                diamondPrice="1600"
                diamondPriceCurrency="€"
                diamondFeatures={["Instalación Wordpress", "Plantilla y editor personalizado", "Responsive Design", "Hasta 25 páginas", "Optimización de imágenes", "3 idiomas", "Instalación de modulos premium", "Optimización SEO premium"]}
                diamondException={["* Los contenidos y las imágenes deberán ser entregadas por el cliente.", "* Hosting, dominio y módulos no incluidos. (En caso de ser necesario, se contabilizará a parte.", "* Formación de Wordpress no incluida. Coste 150€"]}
            />

            <CallToAction
                color="green"
                title="¿Quieres una página web que genere leads?"
                text="¡Somos tu agencia de diseño web en Barcelona!"
                button="¡Despunta con nosotros en el mercado! ¡Contáctanos!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default PaginasWebPage
